@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: #cdf564;
  color: #212121;
}

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: grid;
  place-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: #f3f4f6;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;

  &:checked {
    background-color: #cdf564;
    border-color: #cdf564;
    &:before {
      content: "";
      display: block;
      width: 0.625rem;
      height: 0.625rem;
      clip-path: polygon(14% 44%, 0% 70%, 50% 100%, 100% 25%, 85% 11%, 50% 58%);
      background-color: #163255;
    }
  }
}
